/* Rich Text Editor styling with scroll bars */
.rich-text-wrapper {
  height: 400px; /* Fixed height to ensure scrolling */
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Make the editor component take full space within its container */
.scrollable-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ql-container {
  flex-grow: 1 !important;
  height: auto !important;
  font-size: 16px !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide overflow at container level */
}

.ql-toolbar {
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #ccc !important;
  flex-shrink: 0; /* Don't let toolbar shrink */
}

/* This is the key class for scrolling - the editable area */
.ql-editor {
  overflow: auto !important; /* Enable both scrollbars when needed */
  min-height: 200px;
  max-height: 350px; /* Allow scrolling after this height */
  font-family: inherit;
  word-break: normal;
  white-space: pre-wrap; /* Allow text wrapping */
}

/* Enable horizontal scrolling for code blocks and tables */
.ql-editor pre, 
.ql-editor table {
  overflow-x: auto !important;
  white-space: pre;
  max-width: 100%;
}

/* Match your form styles */
.ql-container.ql-snow {
  border: 1px solid #ccc !important;
  border-top: none !important;
}

/* Error state styling */
.error .ql-toolbar.ql-snow,
.error .ql-container.ql-snow {
  border-color: #dc3545 !important;
}

/* Focus state */
.ql-container.ql-snow:focus-within {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

/* Make input container take full height of its parent */
.form-group .input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 400px; /* Minimum height for the container */
  width: 100%;
}

/* Make Quill container take full available height */
.react-quill {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Make sure the field editor for rich text takes available height */
.field-editor[data-fieldname="description"] {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
}

/* Make the form group inside the rich text field editor take full height */
.field-editor[data-fieldname="description"] .form-group {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Custom scrollbar styling (optional) */
.ql-editor::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ql-editor::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ql-editor::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
  background: #555;
}