.tournament-draws {
  padding: 20px;
}

.tournament-draws h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
}

.bracket-container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  padding-bottom: 20px;
}

.bracket-round {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  min-width: 200px;
}

.round-header {
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
}

.round-matches {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bracket-match {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.player {
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.player.top {
  border-bottom: 1px solid #ddd;
}

.player.winner {
  background-color: #e6f7ff;
  font-weight: bold;
}

.tournament-draws-loading,
.tournament-draws-error,
.no-draws-message {
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin: 20px 0;
}

.tournament-draws-error {
  color: #d32f2f;
  background-color: #ffebee;
}

.using-default-data {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
  .bracket-round {
    min-width: 150px;
    margin-right: 20px;
  }
  
  .player {
    padding: 8px;
    font-size: 14px;
  }
}