.publish-button,
.unpublish-button,
.publish-club-button,
.unpublish-club-button,
.submit-button,
.next-button {
    margin-left: 10px;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
    font-size: 1rem;
    min-width: 120px;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* Apply color styles to both standard and club buttons */
.publish-button,
.publish-club-button {
    background-color: #28a745;
    color: white;
}

.publish-button:hover:not(:disabled),
.publish-club-button:hover:not(:disabled) {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.unpublish-button,
.unpublish-club-button {
    background-color: #dc3545;
    color: white;
}

.unpublish-button:hover:not(:disabled),
.unpublish-club-button:hover:not(:disabled) {
    background-color: #c82333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.publish-button:disabled,
.unpublish-button:disabled,
.publish-club-button:disabled,
.unpublish-club-button:disabled,
.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    box-shadow: none;
}

/* Add to createtournament/components/FormActions.css */
.form-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
}

@media (max-width: 768px) {
    .form-actions {
        flex-direction: column;
    }
    
    .publish-button,
    .unpublish-button,
    .publish-club-button,
    .unpublish-club-button,
    .submit-button,
    .next-button {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
    }
}
