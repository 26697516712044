.tournament-list-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.tournament-list-container.loading,
.tournament-list-container.error,
.tournament-list-container.empty {
    text-align: center;
    padding: 40px;
}

.tournament-list-container h2 {
    margin-bottom: 20px;
}

.create-tournament-button {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 20px;
}

.tournament-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 20px;
}

.tournament-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.tournament-logo {
    height: 160px;
    overflow: hidden;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tournament-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.placeholder-logo {
    font-size: 48px;
    color: #999;
}

.tournament-details {
    padding: 15px;
    flex-grow: 1;
}

.tournament-details h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.tournament-status {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.tournament-dates {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.tournament-description {
    font-size: 14px;
    line-height: 1.4;
    color: #333;
}

/* Update tournament-actions to handle multiple buttons */
.tournament-actions {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-top: 1px solid #eee;
}

.tournament-actions a,
.tournament-actions button {
    flex: 1;
    padding: 8px 12px;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}

.view-button {
    background-color: #2196F3;
    color: white;
}

.edit-button {
    background-color: #FFC107;
    color: #333;
}

.delete-button {
    background-color: #F44336;
    color: white;
    border: none;
}

.schedule-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.schedule-button:hover {
    background-color: #2980b9;
}

@media (max-width: 768px) {
    .tournament-grid {
        grid-template-columns: 1fr;
    }
}