/* Add these styles to your existing CSS */

/* Make tournament cards clickable */
.tournament-card {
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tournament-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Register button */
.register-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.register-button:hover {
  background-color: #388e3c;
}

/* Loading and error states */
.loading, .error-message, .empty-state {
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

.loading {
  background-color: #e3f2fd;
  color: #0d47a1;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
}

.empty-state {
  background-color: #f5f5f5;
  color: #616161;
  padding: 40px 20px;
}