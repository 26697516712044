.operator-details-container {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.operator-details-title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
  color: #16486c;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.operator-details-section {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

/* Form group styling */
.operator-details-container .form-group {
  margin-bottom: 15px;
}

.operator-details-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Select styling */
.operator-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

/* Checkbox styling */
.checkbox-label {
  display: flex;
  align-items: center;
}

.operator-checkbox {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

/* Help text */
.help-text {
  font-size: 14px;
  color: #6c757d;
  margin-top: 4px;
  margin-left: 28px;
}