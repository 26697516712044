.schedule-tournament-container {
  display: flex;
  min-height: calc(100vh - 60px); /* Adjust based on your header/footer */
  background-color: #f8f9fa;
  padding: 20px;
  flex-direction: column;
  height: 100%;
}

/* For the full-width main content */
.schedule-main-content.full-width {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

/* Left Sidebar */
.schedule-sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 0 20px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo {
  font-size: 24px;
  margin-right: 10px;
}

.logo-container h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.sidebar-nav li {
  padding: 10px 20px;
  transition: background-color 0.2s;
}

.sidebar-nav li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-nav li.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-left: 3px solid #3498db;
}

.sidebar-nav a {
  color: white;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

/* Main Content Area */
.schedule-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Divisions Navigation */
.divisions-nav {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px;
}

/* Make the parent relative to position the dropdown properly */
.divisions-search {
  position: relative;
  margin-bottom: 15px;
}

.divisions-search input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* You may also want to adjust the divisions-list to have a good height when displaying results */
.divisions-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-top: 2px;
}

.division-item {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}

.division-item:hover {
  background-color: #f8f9fa;
}

.division-item.active {
  background-color: #e3f2fd;
  color: white;
}

/* Add styles for selected division tags */
.selected-divisions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.division-tag {
  display: inline-flex;
  align-items: center;
  background-color: #e1f5fe;
  border-radius: 16px;
  padding: 4px 10px;
  margin: 2px 4px 2px 0;
  font-size: 14px;
}

.remove-division {
  margin-left: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.remove-division:hover {
  color: #d32f2f;
}

/* View Selector */
.view-selector {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
}

.view-tab {
  padding: 15px 25px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 15px;
  font-weight: 600;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
}

.view-tab:hover {
  color: #333;
}

.view-tab.active {
  color: #3498db;
  border-bottom-color: #3498db;
}

/* View Content */
.view-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: white;
}

.view-content h2 {
  margin-top: 0;
  color: #333;
  font-size: 20px;
  margin-bottom: 20px;
}

.no-division-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Planner View Styles */
.planner-grid {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow-x: auto;
}

.time-column, .court-columns {
  display: flex;
  flex-direction: column;
}

.time-column {
  min-width: 80px;
  background-color: #f8f9fa;
  border-right: 1px solid #e0e0e0;
}

.court-columns {
  min-width: 180px;
  flex: 1;
}

.time-header, .court-header {
  padding: 10px;
  font-weight: 600;
  text-align: center;
  background-color: #e9ecef;
  border-bottom: 1px solid #dee2e6;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-slot {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  color: #666;
}

.court-slot {
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  padding: 5px;
  font-size: 12px;
}

.court-slot.match {
  background-color: #e3f2fd;
  cursor: pointer;
  transition: background-color 0.2s;
}

.court-slot.match:hover {
  background-color: #bbdefb;
}

/* Add styles for swap functionality */
.match-content.can-swap {
  background-color: #fff3cd;
  position: relative;
}

.swap-hint {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff9800;
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 0 0 0 4px;
}

/* Update the planner tooltip */
.planner-view::after {
  content: "Tip: Drag matches to move/swap, Ctrl+C to cut, click empty/occupied slot to move/swap";
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  text-align: center;
  width: 100%;
  max-width: 100%;
  white-space: normal;
}

/* Add styles for the transposed planner grid */
.planner-grid.transposed {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}

.court-column {
  min-width: 100px;
  flex-shrink: 0;
  background-color: #f8f9fa;
}

.court-header, .time-header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #e9ecef;
  border-bottom: 1px solid #dee2e6;
}

.court-label {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 500;
}

.time-columns {
  min-width: 120px;
  flex-shrink: 0;
}

/* Draws View Styles */
.bracket-container {
  display: flex;
  gap: 40px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  padding: 30px;
  overflow-x: auto;
}

.bracket-round {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 200px;
}

.bracket-round h4 {
  margin: 0 0 10px;
  color: #555;
  text-align: center;
}

.bracket-match {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.bracket-player {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.bracket-player:last-child {
  border-bottom: none;
}

.bracket-player.winner {
  background-color: #e3f2fd;
  font-weight: 600;
}

/* Add styles for draw view drag and drop */
.bracket-player {
  position: relative;
  cursor: grab;
  transition: background-color 0.15s ease, border-left 0.15s ease;
}

.bracket-player.selected {
  background-color: #bbdefb;
  border-left: 3px solid #2196f3;
}

.bracket-player.can-swap {
  background-color: #fff3cd;
}

.bracket-player .swap-hint {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff9800;
  color: white;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 0 0 0 4px;
}

/* Add a tooltip for the draws view */
.draws-view::after {
  content: "Tip: Select both players in a match to move the entire match, or select individual players. Drag to move/swap within the same round.";
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  text-align: center;
}

/* Add this to your CSS file */
.no-results {
  padding: 10px;
  color: #666;
  font-style: italic;
  text-align: center;
  width: 100%;
}

/* Add this to your CSS file */
.highlight {
  font-weight: bold;
  color: #3498db;
  background-color: rgba(52, 152, 219, 0.1);
}

/* Style for selected matches */
.bracket-match.match-selected {
  background-color: rgba(25, 118, 210, 0.08);
  border: 2px solid #1976d2;
  box-shadow: 0 0 5px rgba(25, 118, 210, 0.3);
}

/* Add a match movement hint */
.match-selected::before {
  content: "Match selected";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1976d2;
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
}

/* Add styles for the search container layout */
.search-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.divisions-nav, .venue-nav {
  flex: 1;
  position: relative;
}

/* Venue search styles */
.venue-search {
  position: relative;
  width: 100%;
}

.venue-search input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.venue-search input:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.venues-list {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.venue-item {
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.venue-item:hover, .venue-item.keyboard-selected {
  background-color: #f5f5f5;
}

.venue-item.active {
  color: #999;
}

.selected-venues {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
}

.venue-tag {
  background-color: #e3f2fd;
  border: 1px solid #bbdefb;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
}

.remove-venue {
  margin-left: 6px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  opacity: 0.7;
}

.remove-venue:hover {
  opacity: 1;
}

/* Player search styles */
.player-nav {
  flex: 1;
  position: relative;
}

.player-search {
  position: relative;
  width: 100%;
}

.player-search input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.player-search input:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.players-list {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.player-item {
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.player-item:hover, .player-item.keyboard-selected {
  background-color: #f5f5f5;
}

.player-item.active {
  color: #999;
}

.player-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.player-details {
  display: flex;
  gap: 10px;
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.player-details .rating {
  background-color: #e3f2fd;
  border-radius: 2px;
  padding: 1px 4px;
}

.player-details .division {
  color: #777;
}

.selected-players {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
}

.player-tag {
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.player-tag .player-rating {
  background-color: #c8e6c9;
  border-radius: 2px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 16px;
}

.remove-player {
  margin-left: 3px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  opacity: 0.7;
}

.remove-player:hover {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .schedule-tournament-container {
    flex-direction: column;
  }
  
  .schedule-sidebar {
    width: 100%;
    height: auto;
  }
  
  .sidebar-nav ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  .sidebar-nav li {
    padding: 8px 15px;
  }
  
  .bracket-container {
    flex-direction: column;
    gap: 20px;
  }
}

/* Score popover styles */
.score-popover {
  position: absolute;
  z-index: 1100;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 320px;
  padding: 16px;
  border: 1px solid #e0e0e0;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.popover-header h4 {
  margin: 0;
  color: #333;
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: #888;
}

.close-btn:hover {
  color: #333;
}

.match-players {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.match-players .player {
  font-weight: 500;
  flex: 1;
}

.match-players .vs {
  margin: 0 10px;
  color: #666;
  font-size: 12px;
}

.match-players .winner {
  color: #2e7d32;
  font-weight: 600;
}

.sets-container {
  margin-bottom: 15px;
}

.set-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.set-label {
  width: 60px;
  font-size: 13px;
  color: #666;
}

.set-row input {
  width: 50px;
  padding: 6px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.score-separator {
  margin: 0 10px;
  color: #666;
}

.remove-set {
  margin-left: 10px;
  background: transparent;
  border: none;
  color: #f44336;
  cursor: pointer;
  font-size: 16px;
  padding: 0 5px;
}

.popover-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-set-btn {
  background-color: transparent;
  border: 1px solid #2196f3;
  color: #2196f3;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.add-set-btn:hover {
  background-color: rgba(33, 150, 243, 0.05);
}

.main-actions {
  display: flex;
  gap: 10px;
}

.cancel-btn {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn {
  background-color: #2196f3;
  border: 1px solid #2196f3;
  color: white;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #1976d2;
}

/* Add this to your ScheduleTournament.css file */
.tournament-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 20px;
  margin-bottom: 20px;
}

.tournament-header h1 {
  margin: 0 0 10px 0;
  color: #2c3e50;
  font-size: 24px;
}

.tournament-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 14px;
  color: #6c757d;
}

.tournament-dates, .tournament-location, .tournament-id {
  display: flex;
  align-items: center;
}

.date-label, .location-label, .id-label {
  font-weight: 600;
  margin-right: 5px;
}

.loading-tournament {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #6c757d;
}

/* Add to your CSS file */
.divisions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.select-all-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
}

.selected-count {
  font-size: 12px;
  color: #666;
  margin: 5px 0;
}

.checkbox-indicator {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4CAF50;
  font-weight: bold;
}

/* Add to your CSS file or add this style block to your component */
.active-divisions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0;
  max-height: 200px;
  overflow-y: auto;
}

.division-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e1f5fe;
  border-radius: 4px;
  padding: 6px 10px;
  margin: 0;
  width: 100%;
}

.division-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-division {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 0 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-division:hover {
  color: #f44336;
}

/* Improve the suggestions list style */
.division-suggestions {
  margin-top: 5px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.division-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.division-item {
  padding: 8px 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.division-item:hover {
  background-color: #f5f5f5;
}

.division-item.keyboard-selected {
  background-color: #e3f2fd;
}

/* Add these styles to your ScheduleTournament.css file */
.loading-overlay {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #666;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-message {
  padding: 15px;
  background-color: #ffebee;
  color: #c62828;
  border-left: 4px solid #c62828;
  margin-bottom: 20px;
  border-radius: 4px;
}

.no-selection-message,
.no-matches-message {
  padding: 30px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #757575;
  font-size: 16px;
  margin: 20px 0;
}

/* Top row with filters */
.top-filters-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.filter-container {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

/* Main content area for views */
.main-content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

/* View selector tabs */
.view-tabs {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.tab {
  padding: 12px 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s;
  color: #000; /* Make unselected tab text black */
}

.tab:hover {
  background-color: #e9e9e9;
}

.tab.active {
  background-color: white;
  border-bottom: 3px solid #4285F4;
  color: #4285F4; /* Keep active tab blue */
}

/* View content area */
.view-content {
  flex: 1;
  padding: 20px;
  overflow: auto;
  background-color: white;
}

.planner-view, .draws-view {
  height: 100%;
}

/* Add these styles to your existing CSS file */

/* Simulation Toggle Styles */
.tournament-header-with-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tournament-header {
  margin: 0; /* Remove margin to align properly */
}

.simulation-toggle {
  display: flex;
  align-items: center;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-text {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4285F4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4285F4;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Active simulation indicator */
input:checked + .slider:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  right: 8px;
  top: 9px;
  opacity: 0.8;
}