/* TournamentLogos.css */
.tournament-logos-container {
  margin-bottom: 20px;
}

.logo-drop-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  margin: 15px 0;
  transition: all 0.3s ease;
}

.logo-drop-area.dragging {
  background-color: #f0f8ff;
  border-color: #007bff;
}

.drop-instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.upload-button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.logo-preview-container {
  margin-top: 20px;
}

.logo-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}

.logo-preview-item {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

.logo-preview-image {
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin-bottom: 5px;
}

.logo-name {
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.remove-logo-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-logo-button:hover {
  background-color: rgba(255, 0, 0, 1);
}

.logo-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #f5f5f5;
  font-size: 0.9rem;
  color: #666;
}