/* Add these styles to your ViewClub.css file */
.club-logo {
  width: 100%;
  height: 100%;
  position: relative;
}

.club-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* This ensures the image covers the area without stretching */
}

.placeholder-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  background-color: #0056b3;
  color: #ffffff;
}

/* Club card styling - REDUCED BY ONLY 10% */
.club-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border: 1px solid #eaeaea;
  padding: 0;
  aspect-ratio: 1 / 1;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  /* NEW: Set width to 90% of available space (10% reduction) */
  width: 90%;
  max-width: 270px;
  /* 90% of original 300px approximate size */
}

.club-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.club-card:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.3);
}

/* Logo area - 40% of card height */
.club-card-left {
  width: 100%;
  height: 40%;
  flex-shrink: 0;
  overflow: hidden;
}

/* Content area - fills remaining space */
.club-card-right {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.club-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Action buttons at bottom of card */
.club-card-actions {
  margin-top: 16px;
  margin-left: 0;
  display: flex;
  justify-content: center;
}

/* View club button styling */
.view-club-button {
  width: 100%;
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
  position: relative;
  z-index: 2;
}

.view-club-button:hover {
  background-color: #004494;
}

/* Create new club section */
.create-new-club {
  margin-top: 24px;
  text-align: center;
}

.create-club-button {
  background-color: white;
  color: #0056b3;
  border: 1px solid #0056b3;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-club-button:hover {
  background-color: #f0f7ff;
}

/* Empty state styling */
.empty-state {
  text-align: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

/* Heading styles */
.clubs-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #333;
}

/* Loading and error states */
.loading,
.error {
  text-align: center;
  padding: 40px;
}

/* Club cards grid layout - SAME NUMBER OF COLUMNS BUT SMALLER CARDS */
.club-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Keep 4 columns */
  gap: 20px;
  margin-bottom: 32px;
}

/* Add this to center and size each card at 50% */
.club-cards-container>div {
  display: flex;
  justify-content: center;
}

@media (max-width: 992px) {
  .club-cards-container {
    grid-template-columns: repeat(2, 1fr);
    /* Two columns on medium screens */
  }

  .club-card {
    width: 90%;
    /* Keep the 90% size */
  }
}

@media (max-width: 768px) {
  .club-cards-container {
    justify-content: center;
    /* Center cards on small screens */
  }

  .club-card {
    width: 90%;
    /* Maintain 90% width on small screens */
    max-width: 315px;
    /* 90% of 350px */
  }
}

@media (max-width: 576px) {
  .club-cards-container {
    grid-template-columns: 1fr;
    /* Single column on smaller screens */
  }

  .club-card {
    width: 90%;
    /* Keep the 90% size */
    max-width: 315px;
    /* 90% of 350px */
  }
}