.division-selector {
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    max-width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.division-selector-vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.available-divisions, .selected-divisions {
    flex: 1;
    min-width: 200px;
}

.division-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.form-section {
    margin-bottom: 20px;
}

.form-section-vertical {
  width: 100%;
  padding: 15px;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.form-control-wrapper {
    width: 100%;
}

/* React-select styling */
.react-select-container {
    width: 100%;
    margin-bottom: 5px;
}

.react-select__control {
    min-height: 38px !important;
    border-color: #ced4da !important;
    box-shadow: none !important;
    border: 1px solid #ddd !important;
}

.react-select__control:hover {
    border-color: #adb5bd !important;
    border-color: #bbb !important;
}

.react-select__control--is-focused {
    border-color: #80bdff !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    box-shadow: 0 0 0 1px #aad4ff !important;
    border-color: #2684FF !important;
}

.react-select__value-container {
    padding: 2px 8px !important;
}

.react-select__multi-value {
    background-color: #e9ecef !important;
    border-radius: 12px !important;
    padding: 0 6px !important;
    background-color: #e6f3ff !important;
    margin: 2px 4px 2px 0 !important;
}

.react-select__multi-value__label {
    color: #0d47a1 !important;
    font-size: 14px !important;
    color: #0066cc !important;
}

.react-select__multi-value__remove {
    color: #0d47a1 !important;
}

.react-select__multi-value__remove:hover {
    background-color: #dc3545 !important;
    color: white !important;
}

.react-select__placeholder {
  color: #999 !important;
}

/* Division preview section */
.division-preview-section {
    position: relative;
    margin-top: 15px;
}

.division-summary {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

.view-divisions-link {
    display: inline-block;
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 16px;
    background: none;
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    margin: 0;
    background-color: #f0f7ff;
    border: 1px solid #d0e3ff;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 14px;
    color: #0066cc;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}

.view-divisions-link:hover {
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    background-color: #e0f0ff;
}

/* Popover styling */
.divisions-popover {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px; /* Increase max width */
    min-width: 400px; /* Increase min width */
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e0e0e0;
    overflow: visible;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
}

.popover-header h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    font-size: 18px;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    font-size: 22px;
    line-height: 1;
    padding: 0 5px;
    cursor: pointer;
    color: #6c757d;
    font-size: 24px;
    color: #999;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.close-button:hover {
    color: #343a40;
    background-color: #f5f5f5;
    color: #333;
}

/* Update the .divisions-list class */
.divisions-list {
    display: flex;
    flex-direction: column; /* Change to column layout */
    padding: 15px;
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 20px;
    overflow-y: auto;
    max-height: 60vh;
}

.division-item {
    background-color: #e3f2fd;
    border: 1px solid #90caf9;
    border-radius: 16px;
    padding: 6px 12px;
    font-size: 14px;
    color: #0d47a1;
    margin-bottom: 8px; /* Increase spacing between items */
    width: fit-content; /* Make items only as wide as needed */
    max-width: 100%; /* Prevent overflow */
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
}

.division-item.available {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    cursor: pointer;
}

.division-item.available:hover:not(:disabled) {
    background-color: #dee2e6;
}

.division-item.available:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.division-item.selected {
    background-color: #cfe2ff;
    border: 1px solid #9ec5fe;
    padding-right: 8px;
}

.division-item:last-child {
  border-bottom: none;
}

.remove-division {
    background: none;
    border: none;
    color: #dc3545;
    margin-left: 8px;
    cursor: pointer;
    font-size: 12px;
    padding: 2px 6px;
}

.remove-division:hover {
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 50%;
}

.no-divisions {
    color: #6c757d;
    font-style: italic;
    margin-top: 10px;
    padding: 30px 0;
    text-align: center;
    color: #999;
    font-style: italic;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 8px;
}

.checkbox-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
}

.checkbox-item input[type="checkbox"] {
    margin-right: 5px;
}

/* Add this to your existing CSS file */

.error-message {
    color: #dc3545;
    font-size: 13px;
    margin-top: 5px;
    font-weight: 500;
    color: #d32f2f;
    font-size: 0.85rem;
    margin-top: 4px;
}

/* Reset any problematic styles from parent components */
.division-selector-vertical input,
.division-selector-vertical select {
  opacity: 1 !important;
  position: relative !important;
}

/* For better multi-select visibility */
.react-select__multi-value {
  margin: 2px 4px 2px 0 !important;
}

/* Select All button styling */
.select-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.select-all-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #333;
  transition: all 0.2s ease;
}

.select-all-button:hover {
  background-color: #e0e0e0;
  border-color: #999;
}

.select-all-button:active {
  background-color: #d0d0d0;
}

/* Fix multi-select menu positioning */
.react-select__menu {
  z-index: 9999 !important;
  position: absolute !important;
  margin-top: 4px !important;
}

/* Fix multi-select options display */
.react-select__option {
  cursor: pointer !important;
  user-select: none !important;
}

/* Fix multi-value display */
.react-select__multi-value {
  background-color: #e8f0fe !important;
  border-radius: 4px !important;
  margin: 2px !important;
  display: flex !important;
  align-items: center !important;
}

.react-select__multi-value__label {
  color: #1a73e8 !important;
  padding: 2px 6px !important;
  font-size: 85% !important;
}

.react-select__multi-value__remove {
  color: #1a73e8 !important;
  padding: 0 4px !important;
  cursor: pointer !important;
}

.react-select__multi-value__remove:hover {
  background-color: #d1e5fd !important;
  color: #0052cc !important;
}

/* Make sure dropdown options are visible */
.react-select__menu-list {
  max-height: 300px !important;
  overflow-y: auto !important;
}

/* Fix focused state */
.react-select__control--is-focused {
  border-color: #2684ff !important;
  box-shadow: 0 0 0 1px #2684ff !important;
}

/* Fix control appearance */
.react-select__control {
  min-height: 38px !important;
}