/* Check that this CSS is included at the top of your file */

.inline-date-field {
  display: flex !important; 
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  width: 100% !important;
  align-items: center !important;
  margin-bottom: 15px !important;
  margin-top: 0 !important;
  gap: 10px !important;
}

.inline-date-field .date-field-label {
  flex: 0 0 200px !important;
  text-align: right !important;
  margin: 0 !important;
  padding-right: 5px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-weight: 500 !important;
}

.inline-date-field .date-field-content {
  flex: 1 1 auto !important;
  min-width: 220px !important;
  max-width: 400px !important; 
  display: flex !important;
  align-items: center !important;
}

.inline-date-field .date-picker-wrapper {
  width: 100% !important;
  margin: 0 !important;
}

/* Ensure date picker components maintain width */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
  width: 100% !important;
}

/* Fix date input width */
.date-field-input {
  width: 100% !important;
  min-width: 0 !important; /* Allow shrinking if needed */
}

/* Override any potentially conflicting CSS rules from parent containers */
.form-group.inline-date-field {
  flex-direction: row !important;
}

.date-field-container {
  margin-bottom: 20px;
}

.date-field-label {
  font-weight: 500;
}

.date-picker-wrapper {
  margin-bottom: 8px;
}

/* Style for the input field */
.date-field-input {
  width: 100% !important;
  max-width: 320px !important;
  padding: 8px 12px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  height: 38px !important; 
  box-sizing: border-box !important;
}

/* Style for calendar popup */
.react-datepicker {
  font-size: 16px !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1) !important;
}

.react-datepicker__header {
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #ced4da !important;
  padding: 8px !important;
}

.react-datepicker__day--selected {
  background-color: #0056b3 !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background-color: #e9ecef !important;
}

.react-datepicker__triangle {
  display: none !important;
}

/* Help and error text styles */
.error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 6px;
}

.date-field-formatted {
  margin-top: 8px;
  color: #495057;
  font-size: 0.9rem;
}

/* Style dropdown menus */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: #e9ecef;
}

/* Add these styles for better date display */

/* Make the formatted date more prominent */
.formatted-value.date-field-formatted {
  display: none;
}

/* Add a calendar icon to the formatted date */
.formatted-value.date-field-formatted::before {
  display: none;
}

/* Add these styles for horizontal layout */

/* Fix the horizontal layout */

/* Modify the inline-date-field class */
.inline-date-field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  gap: 10px; /* Control spacing between items */
}

.inline-date-field .date-field-label {
  width: 180px; /* Fixed width */
  flex-shrink: 0; /* Prevent shrinking */
  text-align: right;
  padding-right: 0; /* Remove padding since we use gap */
  white-space: nowrap;
  font-weight: 500;
}

.inline-date-field .date-field-content {
  flex: 1;
  display: flex;
  min-width: 0; /* Allow content to shrink if needed */
}

.inline-date-field .date-picker-wrapper {
  width: 100%;
  max-width: 320px;
  margin: 0; /* Remove all margins */
}

/* Ensure the DatePicker component and its wrapper expand properly */
.inline-date-field .react-datepicker-wrapper,
.inline-date-field .react-datepicker__input-container {
  width: 100% !important;
  display: block !important;
}

/* Make error messages appear below the row */
.inline-date-field .error-message {
  margin-left: 190px; /* Label width + gap */
  margin-top: 5px;
}

/* Additional responsive fix for smaller screens */
@media (max-width: 768px) {
  .inline-date-field {
    flex-wrap: wrap; /* Allow wrapping on small screens */
  }
  
  .inline-date-field .date-field-label {
    flex: 0 0 100%; /* Full width on small screens */
    text-align: left;
    margin-bottom: 5px;
  }
  
  .inline-date-field .date-field-content {
    flex: 0 0 100%; /* Full width on small screens */
  }
  
  .inline-date-field .error-message {
    margin-left: 0;
  }
}

/* Adjust input width since we're constraining parent width */
.inline-date-field .react-datepicker-wrapper,
.inline-date-field .react-datepicker__input-container,
.inline-date-field .date-field-input {
  width: 100% !important;
  max-width: 100% !important;
}

/* Fixed responsive behavior */
@media (max-width: 576px) {
  .inline-date-field {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .inline-date-field .date-field-label {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
  
  .inline-date-field .error-message {
    margin-left: 0;
  }
}