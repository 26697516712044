.menu-container {
  position: relative;
  display: inline-block;
}

/* Ensure "Sign In" text is always visible */
.sign-in-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: black;
}

.sign-in-button:hover {
  text-decoration: underline;
}

/* Dropdown menu (Hidden by default, appears when clicked) */
.dropdown-menu {
  position: absolute;
  top: 40px;
  right: 0;
  width: 250px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  z-index: 10;
}

/* Sign-in prompt */
.menu-header {
  background: #f5faff;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  border-bottom: 1px solid #eaeaea;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-info span {
  margin-left: 10px;
  font-weight: bold;
  color: #333;
}

.logout-button {
  background-color: #f3f3f3;
  color: #333;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  text-align: center;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #e0e0e0;
}

.sign-in-link {
  display: flex;
  align-items: center;
  background-color: #734f96;
  color: white;
  padding: 8px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  margin-top: 5px;
  border-radius: 4px;
  padding: 8px 12px;
  text-align: left;
}

.sign-in-link:hover {
  background-color: #633d85;
}

/* Account options */
.menu-items {
  padding: 10px 0;
}

.menu-title {
  font-weight: bold;
  font-size: 14px;
  padding-left: 10px;
}

.menu-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  padding: 8px 12px;
  cursor: pointer;
}

.menu-items li:hover {
  background: #f0f0f0;
}

.icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 3px;
}

.icon-inline {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  vertical-align: middle;
}