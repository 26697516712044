/* FileField.css */
.file-field {
  margin-bottom: 20px;
}

.inline-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;
  gap: 10px;
}

.file-field-label {
  flex: 0 0 200px;
  text-align: right;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.file-field-content {
  flex: 1;
  max-width: 600px;
}

.file-upload-container {
  margin-bottom: 10px;
}

.selected-filename {
  margin-top: 8px;
  padding: 6px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-button {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  font-size: 16px;
  padding: 2px 6px;
  border-radius: 50%;
}

.clear-button:hover {
  background-color: #f8d7da;
}

.file-preview {
  margin-top: 10px;
}

.file-preview-image {
  max-width: 200px;
  max-height: 150px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
}

.help-text {
  color: #6c757d;
  font-size: 0.85rem;
  margin-top: 5px;
}

.error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 6px;
}