/* Error styles */
.error {
    border: 2px solid red;
}

.error-message {
    color: red;
    font-size: 0.75rem;
    margin-top: 5px;
    display: block;
    text-align: left;
    width: 100%;
    min-height: 1rem;
    /* Reserve space for error message to prevent layout shifts */
    background-color: #f8d7da;
    color: #721c24;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid #f5c6cb;
}

/* Form group layout - adjust vertical alignment */
.form-group {
    position: relative;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: baseline; /* Change from center to baseline for better text alignment */
    gap: 10px;
    width: 100%;
}

/* Label styling - add padding to move text down slightly */
.form-group label {
    font-weight: 500;
    color: #495057;
    font-size: 15px;
    text-align: left;
    padding-left: 0;
    padding-top: 8px; /* Add top padding to align better with input fields */
}

/* Input field styling */
.form-group input {
    padding: 12px 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.2s;
    width: 100%;
    box-sizing: border-box;
}

.form-group input:focus {
    border-color: #734f96;
    outline: none;
    box-shadow: 0 0 0 3px rgba(115, 79, 150, 0.2);
}

.form-group input:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

/* Container for the entire form to provide consistent width */
.signin-form-container {
    width: 80%;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

/* Style for the form element itself */
.signin-form-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Style for the form heading */
.signin-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

/* Style for the submit button */
.submit-button {
    background-color: #734f96;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
}

/* Style for button on hover */
.submit-button:hover {
    background-color: #E6E6FA;
}

/* Style for the signup link */
.signup-link {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    background: none;
    border: none;
    color: #734f96;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    font-size: 15px;
    transition: color 0.2s;
    white-space: nowrap; /* Prevent button text wrapping */
}

.signup-link a {
    color: #734f96;
    text-decoration: none;
    font-weight: bold;
}

.signup-link a:hover {
    text-decoration: underline;
}

.signup-link:hover:not(:disabled) {
    color: #5a3d77;
    text-decoration: underline;
}

/* Adding responsive behavior for smaller screens */
@media (max-width: 768px) {
    .signin-form-container {
        width: 95%;
    }
}

/* General error styles */
.general-error {
    background-color: #ffeded;
    /* Light red background */
    border: 1px solid #f5c6cb;
    color: #721c24;
    /* Dark red text */
    padding: 10px 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
    padding: 20px;
    background-color: #f8f9fa;
}

.signin-card {
    width: 100%;
    max-width: 450px;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.signin-subtitle {
    text-align: center;
    color: #6c757d;
    margin-bottom: 24px;
    font-size: 16px;
}

.signin-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Move options below the form button */
.form-options {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.remember-me {
    display: flex;
    align-items: center;
    gap: 8px;
}

.remember-me input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
    accent-color: #734f96;
}

.remember-me label {
    color: #495057;
    font-size: 14px;
    cursor: pointer;
}

.forgot-password-link {
    background: none;
    border: none;
    color: #734f96;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    transition: color 0.2s;
}

.forgot-password-link:hover {
    color: #5a3d77;
}

.signin-button {
    background-color: #734f96;
    color: white;
    border: none;
    padding: 14px 20px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin-button:hover:not(:disabled) {
    background-color: #5a3d77;
}

.signin-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Signup prompt alignment adjustment */
.signup-prompt {
    display: flex;
    justify-content: center;
    align-items: baseline; /* Change from center to baseline */
    gap: 10px;
    margin-top: 24px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

/* Signup prompt text adjustment */
.signup-prompt p {
    color: #6c757d;
    margin: 0;
    font-size: 15px;
    padding-top: 4px; /* Add slight padding to align with button */
}

/* Media queries for responsiveness */
@media (max-width: 500px) {
    .signin-card {
        padding: 20px;
    }

    h1 {
        font-size: 24px;
    }

    .form-options {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .forgot-password-link {
        margin-left: 0;
    }

    .form-group {
        grid-template-columns: 1fr;
        gap: 5px;
    }
    
    .form-group label {
        text-align: left;
        padding-right: 0;
    }
    
    /* Keep signup prompt on one line */
    .signup-prompt {
        flex-wrap: nowrap;
        font-size: 14px; /* Slightly smaller font on mobile */
    }
    
    .signup-link {
        font-size: 14px; /* Match the text size */
    }
}