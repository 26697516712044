/* Full-width body styling */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.home-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Remove any potential borders */
.home-container, .home-header, .home-section {
  border: none;
}

.welcome-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 30px;
}

.sign-in-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.sign-in-button:hover {
  background-color: #004494;
}

.home-section {
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 0;
  padding: 20px;
  box-shadow: none;
  width: 100%;
  margin: 20px 0;
  padding: 0;
  box-sizing: border-box;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  padding-left: 20px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #eaeaea;
}

.section-header h2 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
}

.create-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.create-button:hover {
  background-color: #004494;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  margin-top: 15px;
}

.card {
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Tournament card logo styling */
.card-logo {
  height: 140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  position: relative;
}

.tournament-logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* This preserves aspect ratio */
  max-width: 100%;
  max-height: 140px;
  padding: 8px;
}

.placeholder-logo {
  font-size: 3rem;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #0056b3;
  color: #ffffff;
}

/* Tournament card styling */
.tournament-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: white;
  cursor: pointer;
}

.tournament-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.card-content {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-content h3 {
  margin: 0 0 10px;
  font-size: 1.1rem;
}

.card-status, .card-date {
  margin: 4px 0;
  font-size: 0.9rem;
  color: #666;
}

.card-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.card-status {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.card-location, 
.card-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 0;
}

.empty-state {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
}

.loading, 
.error-message {
  padding: 20px;
  text-align: center;
}

.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .card-grid {
    grid-template-columns: 1fr;
  }
}

/* Tournament card styles */
.tournament-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  position: relative;
}

.tournament-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.card-logo-container {
  height: 120px;
  overflow: hidden;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tournament-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 8px;
}

.card-header {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
}

.card-header h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: #333;
  line-height: 1.3;
}

.organization-badge {
  display: inline-block;
  background-color: #e7f2fa;
  color: #1976d2;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
}

.card-dates {
  padding: 12px 16px 8px;
  display: flex;
  align-items: center;
}

.date-range {
  font-size: 14px;
  color: #555;
}

.card-location {
  padding: 0 16px 12px;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
}

.location-icon {
  margin-right: 6px;
  color: #f44336;
}

.card-type {
  padding: 0 16px 12px;
  font-size: 14px;
  color: #666;
  font-style: italic;
}

/* Empty state */
.empty-state {
  text-align: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  margin: 20px 0;
}

.empty-state h2 {
  color: #333;
  margin-bottom: 16px;
}

.empty-state p {
  color: #666;
  margin-bottom: 24px;
}

.create-first-tournament {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-first-tournament:hover {
  background-color: #388e3c;
}

/* Update the ad banner styles to accommodate an image */

/* Ad Banner Styles */
.ad-banner {
  width: 1280px; /* Fixed width */
  height: 130px; /* Fixed height */
  margin: 20px auto; /* Center horizontally with auto margins */
  overflow: hidden; /* Hide any overflow */
  position: relative;
  max-width: 100%; /* Ensures it doesn't overflow small screens */
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ad-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none;
}

.ad-banner-image {
  width: 1280px; /* Match the container width */
  height: 130px; /* Match the container height */
  object-fit: cover; /* Cover the area without distortion */
  display: block; /* Remove any extra spacing */
}

/* For smaller screens, maintain aspect ratio */
@media (max-width: 1280px) {
  .ad-banner {
    height: auto;
    width: 100%;
    aspect-ratio: 1280/130; /* Maintain aspect ratio */
    border-radius: 8px; /* Slightly smaller radius on mobile */
  }
  
  .ad-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* Hide the text placeholder when using an image */
.ad-placeholder {
  display: none;
}

/* Horizontal Scrollable Containers */
.scrollable-container-wrapper {
  position: relative;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.scrollable-card-grid {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
  padding: 15px 50px; /* Add padding on sides for arrows */
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  padding: 0 50px; /* Give space for the arrows on the sides */
  box-sizing: border-box;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable-card-grid::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari/Opera */
}

/* Make tournament cards work in a horizontal layout */
.scrollable-card-grid .tournament-card {
  flex: 0 0 auto;
  width: 390px; /* Increased from 260px (260px * 1.5 = 390px) */
  margin-right: 20px; /* Slightly increased margin too */
  padding: 15px; /* Increased padding for better proportions */
}

/* Scroll Arrows */
.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 4px; /* Changed from 50% (circle) to 4px for slight rounded corners */
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  font-size: 24px; /* Slightly larger for the angle brackets */
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
  font-weight: bold; /* Make the arrows bold */
  color: #333; /* Darker color for better visibility */
}

.scroll-arrow:hover {
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0,0,0,0.25);
}

.scroll-arrow:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3), 0 2px 5px rgba(0,0,0,0.2);
}

.left-arrow {
  left: 5px;
}

.right-arrow {
  right: 5px;
}

.scroll-arrow.left-arrow {
  left: 10px;
  z-index: 10;
}

.scroll-arrow.right-arrow {
  right: 10px;
  z-index: 10;
}

@media (max-width: 768px) {
  .ad-banner {
    height: 120px;
  }
  
  .ad-placeholder {
    font-size: 18px;
  }
  
  .scroll-arrow {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  
  .scroll-arrow.left-arrow {
    left: 5px;
  }
  
  .scroll-arrow.right-arrow {
    right: 5px;
  }
  
  .scrollable-card-grid {
    padding: 0 40px;
  }
}

/* Add these styles to your Home.css file */

/* USAP Swimlane - Gradient from red to blue */
.home-section:has(> .section-header > h2:contains("USA Pickleball")) {
  background: linear-gradient(to bottom, #b11f29 0%, #093254 100%);
  padding-bottom: 20px;
  color: white;
  border-radius: 4px;
}

/* USAP Section Header */
.home-section:has(> .section-header > h2:contains("USA Pickleball")) .section-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* USAP Section Title */
.home-section:has(> .section-header > h2:contains("USA Pickleball")) .section-header h2 {
  color: white;
}

/* APP Tour Swimlane - Solid cyan color */
.home-section:has(> .section-header > h2:contains("APP Tour")) {
  background-color: #24cfd9;
  padding-bottom: 20px;
  color: white;
  border-radius: 4px;
}

/* APP Section Header */
.home-section:has(> .section-header > h2:contains("APP Tour")) .section-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* APP Section Title */
.home-section:has(> .section-header > h2:contains("APP Tour")) .section-header h2 {
  color: white;
}

/* Ensure scroll arrows are more visible against colored backgrounds */
.home-section:has(> .section-header > h2:contains("USA Pickleball")) .scroll-arrow,
.home-section:has(> .section-header > h2:contains("APP Tour")) .scroll-arrow {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Hover state for arrows on colored backgrounds */
.home-section:has(> .section-header > h2:contains("USA Pickleball")) .scroll-arrow:hover,
.home-section:has(> .section-header > h2:contains("APP Tour")) .scroll-arrow:hover {
  background-color: white;
}

/* USAP Swimlane - Background with white patch between red and blue */
.usap-section {
  position: relative;
  background: linear-gradient(to bottom, 
    #b11f29 0%, 
    #b11f29 calc(50% - 20px),
    #093254 calc(50% + 20px),
    #093254 100%);
  padding-bottom: 20px;
  color: white;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden; /* Ensure the pseudo-element stays within bounds */
}

/* White patch separator with adjusted position */
.usap-section::after {
  content: "";
  position: absolute;
  top: 50%; /* Place at exact center */
  transform: translateY(-50%); /* Center it vertically */
  left: 0;
  right: 0;
  height: 40px; /* Match the height of the arrows */
  background-color: white;
  z-index: 1; /* Above the background, below the content */
}

/* Ensure content stays above the white patch */
.usap-section .section-header,
.usap-section .scrollable-container-wrapper {
  position: relative;
  z-index: 2;
}

/* USAP Section Header - keep it above the white patch */
.usap-section .section-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 2;
}

/* Scroll arrows for USAP section - integrate with white patch */
.usap-section .scroll-arrow {
  background-color: white; /* Match the white patch exactly */
  color: #333;
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  z-index: 3; 
  width: 40px;
  height: 40px;
  top: 50%; /* Place at exact center */
  transform: translateY(-50%); /* Center it vertically */
}

/* Hover effect for arrows in USAP section */
.usap-section .scroll-arrow:hover {
  background-color: #f5f5f5; /* Slightly different on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

/* Position arrows to align with white patch */
.usap-section .scroll-arrow.left-arrow {
  left: 10px;
}

.usap-section .scroll-arrow.right-arrow {
  right: 10px;
}

/* Adjust the card grid to align properly */
.usap-section .scrollable-card-grid {
  padding: 20px 50px; /* Add more top/bottom padding */
}

/* Make tournament cards stand out more in USAP section */
.usap-section .tournament-card {
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

/* APP Tour Swimlane - Solid cyan color */
.app-section {
  background-color: #24cfd9;
  padding-bottom: 20px;
  color: white;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* Ensure scroll arrows are more visible against colored backgrounds */
.usap-section .scroll-arrow,
.app-section .scroll-arrow {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 3; /* Ensure arrows are above all other elements */
}

/* Make tournament cards stand out more against colored backgrounds */
.usap-section .tournament-card,
.app-section .tournament-card {
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2; /* Ensure cards are above the white patch */
}

/* Ensure scroll arrows are more visible against colored backgrounds */
.usap-section .scroll-arrow,
.app-section .scroll-arrow {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Hover state for arrows on colored backgrounds */
.usap-section .scroll-arrow:hover,
.app-section .scroll-arrow:hover {
  background-color: white;
}

/* Add some padding around the cards for better visibility */
.usap-section .scrollable-card-grid,
.app-section .scrollable-card-grid {
  padding: 10px 50px;
}

/* Make tournament cards stand out more against colored backgrounds */
.usap-section .tournament-card,
.app-section .tournament-card {
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Clean up and fix USAP swimlane white patch and arrow positioning */

/* USAP Swimlane - Background with white patch */
.usap-section {
  position: relative;
  background: linear-gradient(to bottom, 
    #b11f29 0%, 
    #b11f29 calc(50% - 10px),
    #093254 calc(50% + 30px),
    #093254 100%);
  padding-bottom: 20px;
  color: white;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}

/* White patch separator */
.usap-section::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  height: 40px;
  background-color: white;
  z-index: 1;
}

/* USAP specific scroll arrow styles - OVERRIDE all other styles */
.usap-section .scroll-arrow {
  position: absolute !important; /* Use !important to override other styles */
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: white !important;
  color: #333 !important;
  border: none !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2) !important;
  z-index: 3 !important;
  width: 40px !important;
  height: 40px !important;
}

/* Position the left and right arrows specifically */
.usap-section .scroll-arrow.left-arrow {
  left: 10px !important;
}

.usap-section .scroll-arrow.right-arrow {
  right: 10px !important;
}

/* Override hover state for USAP arrows only */
.usap-section .scroll-arrow:hover {
  background-color: #f5f5f5 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) !important;
}

/* Make tournament logos rounded */
.card-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #f9f9f9; /* Light background for the logo area */
}

.tournament-logo {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.tournament-card:hover .tournament-logo {
  transform: scale(1.05);
}

/* For smaller screens, maintain aspect ratio */
@media (max-width: 1280px) {
  .ad-banner {
    height: auto;
    width: 100%;
    aspect-ratio: 1280/130;
    border-radius: 8px; /* Slightly smaller radius on mobile */
  }
  
  .ad-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-logo-container {
    height: 100px; /* Smaller height on mobile */
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}