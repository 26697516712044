.create-tournament-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.create-tournament-header {
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
}

.create-tournament-header h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
    margin-bottom: 10px;
}

.create-tournament-content {
    display: flex;
    gap: 20px;
    flex-direction: row;
}

.form-container {
    flex: 1;
    max-width: 800px;
    padding: 20px;
}

.field-editor {
    margin-bottom: 25px;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    /* Ensure form group takes full width */
    gap: 20px;
}

.form-group label {
    flex: 0 0 30%;
    /* Keep label at fixed width */
    padding-top: 8px;
    font-weight: 500;
    min-width: 150px;
    /* Ensure minimum width for labels */
}

.form-group .input-container {
    flex: 1 1 auto;
    /* Change to grow and shrink as needed, taking all available space */
    width: 100%;
    /* Ensure it has full width */
    min-width: 400px;
    /* Allow container to shrink if needed */
}

.form-group .form-control {
    width: 100%;
}

.form-control {
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.2s;
    box-sizing: border-box;
    /* Ensure padding doesn't expand the width */
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
}

.form-control.error {
    border-color: #f44336;
}

.error-message {
    color: #f44336;
    font-size: 14px;
    margin-top: 5px;
}

.help-text {
    font-size: 14px;
    color: #757575;
    margin-top: 5px;
}

textarea.form-control {
    min-height: 100px;
    resize: vertical;
    width: 100%;
    box-sizing: border-box;
}

.button-primary {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: bold;
}

.button-primary:hover {
    background-color: #104d8a;
}

.button-primary:disabled {
    background-color: #bbdefb;
    cursor: not-allowed;
}

.button-secondary {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.2s;
}

.button-secondary:hover {
    background-color: #e0e0e0;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 18px;
    color: #757575;
}

.general-error {
    background-color: #ffebee;
    color: #d32f2f;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid #ef9a9a;
}

.field-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
    gap: 10px;
}

/* Multiselect styling */
.multiselect-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
}

.multiselect-option {
    margin-right: 15px;
}

/* Checkbox styling */
.checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.checkbox-container:hover input~.checkmark {
    background-color: #f8f9fa;
}

.checkbox-container input:checked~.checkmark {
    background-color: #007bff;
    border-color: #007bff;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.group-editor {
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 15px;
}

.group-editor h3 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 15px;
    color: #495057;
}

.array-editor {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 15px;
}

.array-item {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.array-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.array-summary {
    font-weight: 500;
    color: #16486c;
}

.remove-item-button {
    color: #dc3545;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.add-item-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.add-item-button:hover {
    background-color: #218838;
}

/* Responsive styles */
@media (max-width: 768px) {
    .create-tournament-content {
        flex-direction: column;
    }
}

.field-navigation {
    width: 250px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    align-self: flex-start;
    position: sticky;
    top: 20px;
}

.field-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.field-nav-item {
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.field-nav-item.active {
    background-color: #16486c;
    color: white;
    font-weight: bold;
}

.field-nav-item.active .field-nav-label {
    color: white;
}

.field-nav-item.has-error {
    background-color: #ffebee;
}

.field-nav-error-indicator {
    color: #f44336;
    font-weight: bold;
}

.array-field-editor,
.group-field-editor {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
}

.button-add {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.button-add:hover {
    background-color: #388e3c;
}

.button-delete {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.button-delete:hover {
    background-color: #d32f2f;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-container input {
    margin-right: 10px;
}

.multiselect {
    min-height: 100px;
}

.multiselect-help {
    color: #757575;
    font-size: 12px;
    margin-top: 5px;
}

.progress-bar {
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background-color: #2196f3;
    transition: width 0.3s ease;
}

.progress-text {
    color: #757575;
    font-size: 14px;
    margin-bottom: 20px;
}

/* File field styles */
.file-upload-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.file-preview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 10px;
}

.file-preview-image {
    max-width: 150px;
    max-height: 150px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    object-fit: contain;
    background-color: #ffffff;
}

.file-clear-button {
    padding: 4px 8px;
    font-size: 12px;
}

input[type="file"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

input[type="file"]:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

/* Tournament Logos styling - matching club logos */
.logo-manager {
    margin-bottom: 30px;
}

.logo-section-title {
    font-size: 18px;
    margin-bottom: 8px;
    color: #333;
}

.logo-section-help {
    color: #666;
    margin-bottom: 20px;
}

.logo-upload-area {
    margin-bottom: 20px;
}

.logo-dropzone {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    background-color: #f8f9fa;
    transition: all 0.3s ease;
}

.logo-dropzone.dragactive {
    border-color: #2196f3;
    background-color: #e3f2fd;
}

.logo-file-input {
    display: none;
}

.logo-upload-label {
    cursor: pointer;
    display: block;
}

.logo-upload-icon {
    font-size: 32px;
    color: #16486c;
    margin-bottom: 15px;
}

.logo-upload-text {
    font-size: 18px;
    margin-bottom: 5px;
    color: #495057;
}

.logo-upload-hint {
    font-size: 14px;
    color: #6c757d;
}

.logo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.logo-card {
    border: 1px solid #dee2e6;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.logo-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.logo-preview-container {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    padding: 15px;
    border-bottom: 1px solid #dee2e6;
}

.logo-preview-image {
    max-width: 100%;
    max-height: 120px;
    object-fit: contain;
}

.logo-details {
    padding: 15px;
}

.logo-description-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
}

.logo-filename {
    font-size: 12px;
    color: #6c757d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.logo-delete-button {
    background-color: #f8f9fa;
    color: #dc3545;
    border: 1px solid #dc3545;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s;
}

.logo-delete-button:hover {
    background-color: #dc3545;
    color: white;
}

/* Remove conflicting styles */
.logos-container,
.logo-item,
.logo-preview,
.logo-actions {
    /* These old selectors are replaced by the new component styles */
    display: none;
}

/* Logos array container */
.logos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.logo-item {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 15px;
    background-color: #f9f9f9;
    width: 250px;
}

.logo-preview {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.logo-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

/* For group fields, maintain the structure */
.group-field>.form-group {
    flex-direction: column;
}

.group-field>.form-group>.group-fields {
    width: 100%;
}

.group-field .sub-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 20px;
    width: 100%;
}

.group-field .sub-field label {
    flex: 0 0 30%;
    padding-top: 8px;
}

.group-field .sub-field .input-container {
    flex: 1 1 auto;
    /* Allow to grow and take all available space */
    min-width: 0;
    /* Allow container to shrink if needed */
}

.group-field .sub-field .input-container {
    flex: 1 1 auto;
    min-width: 0;
}

/* Add address summary display for venues */
.address-summary {
    background-color: #f0f5fa;
    border-left: 3px solid #16486c;
    padding: 10px 12px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-size: 14px;
}

.address-summary p {
    margin: 0;
    line-height: 1.4;
}

.address-summary .venue-name {
    font-weight: bold;
    margin-bottom: 3px;
}

/* Add these styles for better communication settings layout */
.group-field {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.group-field h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    color: #16486c;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

/* Specific styling for checkbox fields */
.checkbox-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.checkbox-field input[type="checkbox"] {
    margin-right: 10px;
    width: auto !important;
    /* Override the 100% width rule */
    height: 16px;
    width: 16px !important;
}

.checkbox-field label {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-bottom: 0;
    cursor: pointer;
}

/* Specific styling for communication settings */
.communication-settings .form-group {
    margin-bottom: 12px;
}

.communication-settings .checkbox-field {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 12px 15px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    width: 100%;
}

.communication-settings .checkbox-field input[type="checkbox"] {
    flex: 0 0 auto;
    width: 18px !important;
    height: 18px !important;
    margin-right: 12px;
    appearance: auto !important;
    /* Force the native checkbox appearance */
    -webkit-appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    position: static !important;
    opacity: 1 !important;
}

.communication-settings .checkbox-field label {
    display: flex;
    align-items: center;
    padding-left: 0;
    /* Remove padding since we're not using positioning */
    cursor: pointer;
    font-weight: normal;
}

/* Remove any conflicting styles */
.communication-settings .checkbox-field .checkmark {
    display: none !important;
    /* Hide the custom checkmark if it's interfering */
}

/* Add hover effect for better UX */
.communication-settings .checkbox-field:hover {
    background-color: #f0f5fa;
}

/* Additional contacts area */
.additional-contacts {
    margin-top: 15px;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: white;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
}

.contact-item input {
    flex: 1;
    margin-right: 10px;
}

.contact-item button {
    padding: 4px 8px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-contact-button {
    margin-top: 10px;
    background-color: #16486c;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.add-contact-button:hover {
    background-color: #0d2b41;
}

/* Make sure select fields also take full width */
select.form-control {
    width: 100%;
}

/* Fix for array fields inside form groups */
.form-group .array-container {
    width: 100%;
}

/* Ensure group fields expand properly */
.group-fields {
    width: 100%;
}

/* Make all form controls take full width */
.form-control,
select.form-control,
textarea.form-control,
input[type="text"],
input[type="textarea"],
input[type="checkbox"],
input[type="number"],
input[type="date"],
input[type="file"] {
    width: 100%;
    box-sizing: border-box;
}

/* Ensure nested fields also take full width */
.nested-group {
    width: 100%;
}

.nested-group-fields {
    width: 100%;
}

/* Fix array containers to take full width */
.array-container {
    width: 100%;
}

/* Fix checkbox field layout */
.checkbox-field label {
    flex: 1;
}

/* Add these rules to fix Communication Settings form control width */
.communication-settings .form-control,
.communication-settings select.form-control,
.communication-settings textarea.form-control,
.communication-settings input[type="text"],
.communication-settings input[type="textarea"],
.communication-settings input[type="number"],
.communication-settings input[type="date"],
.communication-settings input[type="file"] {
    width: 100%;
    box-sizing: border-box;
}

.communication-settings .form-group .input-container {
    flex: 1 1 auto;
    width: 100%;
    min-width: 0;
}

/* Fix for additionalContacts array within communication settings */
.communication-settings .array-container {
    width: 100%;
}

.communication-settings .array-item {
    width: 100%;
    box-sizing: border-box;
}

/* Ensure the group structure doesn't mess up the layout */
.communication-settings .group-fields {
    width: 100%;
}

.communication-settings .sub-field {
    width: 100%;
}

/* Make sponsor inputs take full width */
.communication-settings .sponsors-field input,
.communication-settings .additionalContacts-field input {
    width: 100%;
}

/* Fix for checkbox input styling - override the problematic rules */
input[type="checkbox"] {
    width: 16px !important;
    height: 16px !important;
    margin-right: 10px;
    box-sizing: border-box;
    position: static !important;
    /* Make sure positioning doesn't hide it */
    opacity: 1 !important;
    /* Make sure it's visible */
}

/* Completely disable the custom checkmark styling for communication settings */
.communication-settings .checkbox-container .checkmark,
.communication-settings .checkbox-field .checkmark {
    display: none !important;
}

/* More compact styling for communication settings */
.communication-settings {
    padding: 15px !important;
    margin-bottom: 15px !important;
}

.communication-settings .form-group {
    margin-bottom: 10px !important;
    align-items: center !important;
    /* Center align items vertically */
}

/* Make checkbox fields more compact */
.communication-settings .checkbox-field {
    padding: 8px 12px !important;
    margin-bottom: 8px !important;
    height: auto !important;
}

/* Keep label and input on same row with proper alignment */
.communication-settings .form-group label {
    padding-top: 0 !important;
    /* Remove top padding that's pushing content down */
    margin-bottom: 0 !important;
    line-height: 1.2 !important;
}

/* Make input elements take less vertical space */
.communication-settings .form-control {
    padding: 6px 8px !important;
    height: 34px !important;
}

/* Make textarea more compact */
.communication-settings textarea.form-control {
    padding: 6px 8px !important;
    height: auto !important;
    min-height: 60px !important;
}

/* Fix array fields to be more compact */
.communication-settings .array-container {
    margin-top: 5px !important;
}

.communication-settings .array-item {
    padding: 8px !important;
    margin-bottom: 8px !important;
}

/* Style the section title to be more compact */
.communication-settings h3 {
    margin: 0 0 10px 0 !important;
    padding-bottom: 8px !important;
    font-size: 16px !important;
}

/* Make help text smaller and less space-consuming */
.communication-settings .help-text {
    margin: 2px 0 0 0 !important;
    font-size: 12px !important;
}

/* Fix the add/remove buttons to be more compact */
.communication-settings button.add-item-button,
.communication-settings button.remove-item-button {
    padding: 4px 8px !important;
    font-size: 12px !important;
    height: auto !important;
}

/* Increase field container width */
.current-field-container {
    width: 100%;
}

/* Target specifically the date fields group */
.form-group.date-field-container {
    width: 100%;
}

/* Adjust the important dates section specifically */
[data-fieldname="importantDates"] .form-group,
[data-fieldname="importantDates"] .date-field-container,
div[id="importantDates"] .form-group {
    width: 100%;
    max-width: 700px;
}

/* Improve spacing in date field content */
.date-field-content {
    flex: 1;
    min-width: 320px;
}

/* Adjust for group fields which might contain date fields */
.group-field-container {
    width: 100%;
    max-width: 700px;
}

/* Communication settings compact layout */
.communication-settings {
    margin-bottom: 20px;
}

.communication-settings .inline-group {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
}

/* Checkbox styling */
.comm-checkbox {
    display: flex !important;
    align-items: center !important;
}

.comm-checkbox input[type="checkbox"] {
    margin-right: 8px;
    appearance: auto !important;
    position: static !important;
    opacity: 1 !important;
    width: 18px !important;
    height: 18px !important;
}

.comm-checkbox label {
    font-weight: normal !important;
    margin-bottom: 0 !important;
}

/* Make arrays more compact */
.comm-array .array-item {
    padding: 10px;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 8px;
}

.comm-array .array-controls {
    margin-top: 10px;
}

.comm-array button.add-item-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.comm-array button.add-item-button:hover {
    background-color: #0069d9;
}

/* Fix empty boxes in communication settings */
.communication-settings-group .form-group {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  margin-bottom: 15px !important;
  border: none !important;
  background: none !important;
}

.communication-settings-group .form-group:empty {
  display: none !important;
}

/* Style checkboxes properly */
.comm-checkbox {
  display: flex !important;
}

.comm-checkbox input[type="checkbox"] {
  opacity: 1 !important;
  position: static !important;
  margin-right: 8px;
  width: auto !important;
  height: auto !important;
}

/* Fix all empty containers */
div:empty {
  display: none !important;
}

/* Create a row of checkboxes */
.communication-settings-group .comm-checkbox {
  display: inline-flex !important;
  margin-right: 20px !important;
}

/* Checkbox container */
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

/* Fix communication settings visibility */
.field-editor[data-fieldname="communication"] .comm-settings-container {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  width: 100% !important;
}

/* Force visibility of checkbox inputs */
.field-editor[data-fieldname="communication"] input[type="checkbox"] {
  appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  opacity: 1 !important;
  position: static !important;
  width: 18px !important;
  height: 18px !important;
  margin-right: 8px !important;
}

/* Fix all form controls in communication section */
.field-editor[data-fieldname="communication"] input[type="text"],
.field-editor[data-fieldname="communication"] input[type="email"],
.field-editor[data-fieldname="communication"] select {
  display: block !important;
  width: 100% !important;
  box-sizing: border-box !important;
  height: auto !important;
}

/* Target communication settings by data attribute */
[data-fieldname="communication"] {
  display: block !important;
  visibility: visible !important;
}

/* Target division settings by data attribute */
[data-fieldname="divisionSettings"] {
  display: block !important;
  visibility: visible !important;
}

/* Fix checkbox visibility in communication settings */
[data-fieldname="communication"] input[type="checkbox"] {
  opacity: 1 !important;
  position: static !important;
  appearance: auto !important;
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
}

/* Add these styles to the existing CSS file */
.tournament-stats {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.players-registered {
  display: flex;
  align-items: center;
}

.stats-label {
  font-weight: bold;
  margin-right: 8px;
}

.stats-value {
  font-size: 18px;
  color: #333;
}

.public-toggle-section {
  display: flex;
  flex-direction: column;
}

.registration-warning {
  margin-top: 5px;
  color: #e67e22;
  font-style: italic;
}