.tournament-planner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 150px);
  position: relative;
  overflow: hidden; /* Hide overflow to prevent double scrollbars */
  padding-bottom: 15px;
}

.tournament-grid-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.tournament-grid {
  display: grid;
  grid-template-columns: 100px repeat(auto-fill, 1fr);
  border: 1px solid #ddd;
}

.tournament-grid.horizontal-layout {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd;
  min-width: min-content;
  width: max-content;
  padding-right: 220px; /* Add padding equal to unassigned column width */
  height: calc(100vh - 150px); /* Adjust height to fit the viewport */
}

.court-labels-column {
  display: flex;
  flex-direction: column;
  min-width: 150px; /* Increased from 120px */
  border-right: 1px solid #ddd;
  flex-shrink: 0; /* Prevent column from shrinking */
}

.corner-cell {
  text-align: center;
  font-weight: bold;
  background-color: #f5f5f5;
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
}

.court-label-cell {
  padding: 10px;
  height: 80px; /* Increased from 60px */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  background-color: #f9f9f9;
}

.time-column {
  display: flex;
  flex-direction: column;
  min-width: 200px; /* Increased from 160px */
  border-right: 1px solid #ddd;
  flex-shrink: 0; /* Prevent columns from shrinking */
}

.time-header, .court-header {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  text-align: center;
}

.header-cell {
  padding: 12px 10px; /* Increased vertical padding */
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  text-align: center;
}

.time-slot {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.court-column {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
}

.match-slot {
  height: 60px;
  border-bottom: 1px solid #ddd;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.match-cell {
  padding: 8px;
  height: 80px; /* Increased from 60px */
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #eee;
  min-width: 200px; /* Ensure cells have enough width */
}

.match-item {
  background-color: #e3f2fd;
  border-left: 4px solid #2196f3;
  padding: 10px; /* Increased padding */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Changed from center to space-between */
  cursor: pointer;
  border-radius: 3px;
  overflow: visible; /* Allow text to be fully visible */
  min-height: 60px; /* Add minimum height */
}

.match-item:hover {
  background-color: #bbdefb;
}

.match-players {
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  overflow: visible; /* Show full text */
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 6px; /* Increased gap */
  flex-wrap: wrap;
  line-height: 1.5; /* Increased line height */
  margin-bottom: 6px; /* Add bottom margin */
}

.match-info {
  font-size: 11px;
  color: #666;
  white-space: nowrap;
  overflow: visible; /* Show full text */
  text-overflow: ellipsis;
  margin-top: 4px;
  line-height: 1.2;
}

.empty-slot {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-style: italic;
  min-height: 60px; /* Match the minimum height */
}

/* Responsive styles */
@media (max-width: 768px) {
  .time-header, .court-header, .time-slot {
    padding: 5px;
    font-size: 12px;
  }
  
  .court-label-cell,
  .header-cell {
    padding: 5px;
    font-size: 12px;
  }
  
  .match-players {
    font-size: 11px;
  }
  
  .match-info {
    font-size: 9px;
  }

  .player {
    font-size: 11px;
  }
  
  .vs-separator {
    font-size: 10px;
  }

  .tournament-grid.horizontal-layout {
    padding-right: 180px;
  }
  
  .unassigned-column-container {
    width: 180px;
  }
}

/* Add these styles to your existing TournamentPlanner.css */

/* Styles for interactive features */
.match-item.selected {
  background-color: #bbdefb;
  box-shadow: 0 0 0 2px #2196f3;
}

.match-item.dragging {
  opacity: 0.6;
}

.match-cell.empty-cell {
  cursor: pointer;
}

.match-cell.drag-over {
  background-color: rgba(33, 150, 243, 0.1);
}

.swapping-mode .match-item:hover {
  background-color: #ffe0b2;
  border-left: 4px solid #ff9800;
}

/* Operation status indicators */
.operation-status {
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.operation-status.swapping {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

.operation-status.copied {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

/* Tips bar */
.planner-tips {
  display: flex;
  gap: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 13px;
  color: #666;
  flex-wrap: wrap;
}

.planner-tips .tip {
  font-weight: 600;
  color: #333;
}

.player {
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player1, .player2 {
  cursor: pointer;
  padding: 4px 8px; /* Increased padding */
  border-radius: 3px;
  max-width: 100%; /* Allow expansion */
  word-break: keep-all; /* Keep names together */
  line-height: 1.4;
  display: inline-block;
}

.player1:hover, .player2:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.vs-separator {
  font-size: 11px;
  color: #777;
  margin: 0 2px;
}

/* Add custom scrollbar styling for better visibility */
.tournament-planner::-webkit-scrollbar {
  height: 8px;
}

.tournament-planner::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.tournament-planner::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.tournament-planner::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Add styles for occupied/empty cells */
.match-cell.occupied-cell.drag-over {
  background-color: rgba(255, 152, 0, 0.1); /* Orange tint for occupied cells when dragging over */
  position: relative;
}

.match-cell.occupied-cell.drag-over::before {
  content: "⇄"; /* Swap icon */
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff9800;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

/* Update empty-cell styles */
.match-cell.empty-cell.drag-over {
  background-color: rgba(33, 150, 243, 0.1); /* Blue tint for empty cells */
}

/* Add styles for changed matches and saving indicators */

/* Highlight changed matches to show they're pending save */
.match-item.changed {
  position: relative;
}

.match-item.changed::after {
  content: "•";
  position: absolute;
  top: 4px;
  right: 4px;
  color: #ff9800;
  font-size: 16px;
  font-weight: bold;
}

/* Saving status indicator */
.operation-status.saving {
  background-color: #e3f2fd;
  border: 1px solid #bbdefb;
  color: #0d47a1;
}

/* Error status indicator */
.operation-status.error {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  color: #c62828;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.operation-status.error button {
  background-color: #ef5350;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.operation-status.error button:hover {
  background-color: #e53935;
}

/* Match details popover */
.match-details-popover {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 90vw; /* Changed from 1100px to 90% of viewport width */
  max-width: 1600px; /* Add max-width to prevent it from being too large on big screens */
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.match-details-popover.dragging {
  cursor: grabbing;
  user-select: none;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  cursor: move;
  position: sticky;
  top: 0;
  z-index: 5;
}

.popover-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #000;
}

.match-details-popover form {
  padding: 16px;
}

.form-section {
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.form-section h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #333;
}

.form-group {
  margin-bottom: 12px;
}

.form-group label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.score-grid {
  display: grid;
  grid-template-columns: 60px 1fr 1fr;
  gap: 8px;
  align-items: center;
}

.score-header {
  font-weight: bold;
  padding: 8px 0;
}

.score-grid input {
  width: 50px;
  padding: 6px;
  text-align: center;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.save-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.save-button:hover {
  background-color: #43a047;
}

.cancel-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .match-details-popover {
    width: 90%;
    max-width: 350px;
    left: 5% !important;
  }
  
  .score-grid {
    grid-template-columns: 40px 1fr 1fr;
  }
  
  .score-grid input {
    width: 40px;
  }
}

/* Add styles for unassigned matches column */

.unassigned-column-container {
  position: fixed;
  top: auto; /* Will be set dynamically with JavaScript */
  right: 0;
  height: calc(100vh - 240px); /* Match the height of the grid minus headers and margins */
  width: 220px;
  z-index: 100;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  border-left: 2px dashed #bdbdbd;
  display: flex;
  flex-direction: column;
}

.unassigned-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  flex: 1;
}

.unassigned-column-container.unassigned-drag-over {
  background-color: #e8f5e9;
  border-left: 2px solid #4caf50;
}

.unassigned-header-cell {
  background-color: #e0e0e0;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  color: #616161;
  padding: 12px 10px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.unassigned-matches-container {
  padding: 10px;
  overflow-y: auto; 
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px; /* Match the spacing between cells */
}

.unassigned-match {
  background-color: #fff3e0; /* Orange-tinted background */
  border-left: 4px solid #ff9800;
  height: 80px; /* Match the height of regular match cells */
  min-height: 60px; /* Same minimum height as regular match items */
  padding: 10px; /* Same padding as regular match items */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Same layout as regular match items */
  margin-bottom: 0; /* Remove margin and use container gap instead */
}

.unassigned-match:active {
  cursor: grabbing;
}

.empty-unassigned-message {
  padding: 20px;
  text-align: center;
  color: #757575;
  font-style: italic;
  height: 80px; /* Match the height of matches */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add styles for collapsible player details */

.player-details {
  margin-bottom: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.player-details summary {
  padding: 8px 12px;
  font-size: 13px;
  color: #666;
  cursor: pointer;
  background-color: #f7f7f7;
}

.player-details summary:hover {
  background-color: #f0f0f0;
}

.player-detail-fields {
  padding: 12px;
  border-top: 1px solid #e0e0e0;
}

.player-detail-fields .form-group {
  margin-bottom: 10px;
}

.player-detail-fields .form-group:last-child {
  margin-bottom: 0;
}

/* Add these styles to your existing CSS file */

/* Match details popover styling */
.match-details-popover {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 90vw; /* Changed from 1100px to 90% of viewport width */
  max-width: 1600px; /* Add max-width to prevent it from being too large on big screens */
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.match-details-popover.dragging {
  cursor: grabbing;
  user-select: none;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  cursor: move;
  position: sticky;
  top: 0;
  z-index: 5;
}

.popover-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #000;
}

/* New split layout styling */
.popover-content {
  display: flex;
  height: calc(85vh - 50px); /* Subtract header height */
  overflow: hidden;
}

.left-sidebar {
  width: 200px;
  background-color: #f8f8f8;
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}

.section-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.section-nav li {
  padding: 14px 16px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
  color: #444;
}

.section-nav li:hover {
  background-color: #f0f0f0;
}

.section-nav li.active {
  background-color: #e8f3ff;
  font-weight: 600;
  color: #0078d4;
  border-left: 3px solid #0078d4;
}

.right-content {
  flex: 1;
  padding: 20px 24px;
  overflow-y: auto;
}

.section-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/* Form layout styles */
.form-grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.form-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
  font-size: 14px;
}

.form-group input,
.form-group select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #0078d4;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 120, 212, 0.25);
}

/* Score grid styling */
.scores-grid {
  display: grid;
  grid-template-columns: 100px 1fr 1fr;
  gap: 12px;
  margin-bottom: 20px;
  align-items: center;
}

.score-header {
  font-weight: 600;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.set-label {
  margin: 8px 0;
}

.score-input {
  width: 60px;
  padding: 8px;
  text-align: center;
  font-size: 16px;
}

.winner-selection {
  margin-top: 24px;
}

.winner-label {
  font-weight: 600;
  margin-bottom: 10px;
}

.radio-group {
  display: flex;
  gap: 20px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-label input {
  margin-right: 8px;
}

/* Action buttons */
.action-buttons {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.save-button,
.cancel-button {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s;
  text-align: center;
}

.save-button {
  background-color: #0078d4;
  color: white;
  margin-top: 8px;
}

.save-button:hover {
  background-color: #006cbe;
}

.cancel-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.cancel-button:hover {
  background-color: #e9e9e9;
}