.division-config-selector {
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    max-width: 100%;
}

.division-config-layout {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}

.division-selection-panel {
    flex: 1;
    max-width: 40%;
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 15px;
}

.division-config-panel {
    flex: 2;
}

h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
}

.help-text {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 16px;
}

.btn-merge {
    margin-top: 15px;
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}

.btn-merge:hover {
    background-color: #218838;
}

.no-divisions-message {
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 4px;
    color: #6c757d;
    font-size: 15px;
    margin-top: 20px;
}

.division-config-item {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 15px;
    border: 1px solid #e9ecef;
}

.division-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #495057;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 8px;
}

.config-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.config-row label {
    flex: 1;
    font-weight: 500;
    color: #495057;
}

.format-select {
    flex: 2;
    min-width: 200px;
}

.player-input {
    flex: 2;
    min-width: 200px;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}

.merged-divisions-section {
    margin-top: 30px;
    border-top: 1px solid #dee2e6;
    padding-top: 20px;
}

.merged-divisions-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.merged-division-item {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 8px;
    padding: 16px;
}

.merged-division-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #91d5ff;
    padding-bottom: 8px;
}

.merged-division-header h4 {
    font-size: 16px;
    font-weight: 600;
    color: #0050b3;
    margin: 0;
}

.btn-remove {
    background-color: #f5222d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 12px;
    cursor: pointer;
}

.btn-remove:hover {
    background-color: #cf1322;
}

.merged-sources {
    margin-bottom: 15px;
}

.merged-sources ul {
    margin-top: 5px;
    padding-left: 20px;
}

.merged-sources li {
    margin-bottom: 4px;
}

/* React Select Styling */
.react-select-container {
    width: 100%;
}

.react-select__control {
    min-height: 38px !important;
    border-color: #ced4da !important;
    box-shadow: none !important;
}

.react-select__control:hover {
    border-color: #adb5bd !important;
}

.react-select__control--is-focused {
    border-color: #80bdff !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.react-select__value-container {
    padding: 2px 8px !important;
}

.react-select__multi-value {
    background-color: #e9ecef !important;
    border-radius: 12px !important;
    padding: 0 6px !important;
}

.react-select__multi-value__label {
    color: #0d47a1 !important;
    font-size: 14px !important;
}

.react-select__multi-value__remove {
    color: #0d47a1 !important;
}

.react-select__multi-value__remove:hover {
    background-color: #dc3545 !important;
    color: white !important;
}

/* Add this to your existing CSS */

.merge-section {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
}

.merge-section h3 {
    margin-top: 0;
}

.merge-section .btn-merge {
    margin-top: 15px;
    background-color: #8e44ad;
    color: white;
}

.merge-section .btn-merge:hover {
    background-color: #703688;
}

/* Merged Division Selection Section */
.merged-division-selection {
    margin-top: 25px;
    padding-top: 20px;
    border-top: 1px solid #e0e0e0;
}

.merged-division-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
}

.btn-merged-division {
    padding: 8px 15px;
    background-color: #e1f5fe;
    border: 1px solid #4fc3f7;
    border-radius: 20px;
    color: #0277bd;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-merged-division:hover {
    background-color: #b3e5fc;
}

.btn-merged-division.selected {
    background-color: #0277bd;
    color: white;
    border-color: #01579b;
}

/* Division Config Items */
.division-config-item.merged {
    background-color: #e1f5fe;
    border: 1px solid #4fc3f7;
}

.division-config-item.inactive {
    opacity: 0.7;
    background-color: #f5f5f5;
}

.merged-division-header-with-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #4fc3f7;
    padding-bottom: 8px;
}

.value-display {
    flex: 2;
    min-width: 200px;
    padding: 8px 12px;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}

/* Merged Divisions Summary */
.merged-divisions-summary {
    margin-top: 30px;
    border-top: 2px solid #e0e0e0;
    padding-top: 25px;
}

.merged-divisions-table {
    overflow-x: auto;
}

.merged-divisions-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.merged-divisions-table th {
    background-color: #f8f9fa;
    padding: 12px 15px;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
    font-weight: 600;
    color: #495057;
}

.merged-divisions-table td {
    padding: 10px 15px;
    border-bottom: 1px solid #dee2e6;
}

.merged-divisions-table tr.selected-row {
    background-color: #e1f5fe;
}

.merged-divisions-table .btn-edit {
    padding: 4px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 12px;
    cursor: pointer;
}

.merged-divisions-table .btn-edit:hover {
    background-color: #0069d9;
}

.merged-divisions-table .btn-remove {
    padding: 4px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}

.merged-divisions-table .btn-remove:hover {
    background-color: #c82333;
}

/* Fix layout issues with division config selector */
.field-editor[data-fieldname="divisionSettings"] {
  display: block !important;
  width: 100% !important;
}

/* Fix select components */
.field-editor[data-fieldname="divisionSettings"] .react-select__control {
  min-height: 38px !important; 
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  opacity: 1 !important;
  visibility: visible !important;
  display: flex !important;
}