/* React 18 specific fixes */

/* Fix for buttons not displaying consistently */
button {
    position: relative;
    overflow: hidden;
}

/* Fix for flex containers with varying content sizes */
.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Fix for scroll issues in modals and overflow containers */
.scroll-container {
    overflow-y: auto;
    overscroll-behavior: contain;
}

/* Ensure consistent form input rendering */
input, select, textarea {
    font-family: inherit;
    font-size: inherit;
}