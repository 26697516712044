/* Add your global styles here */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

h1 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

input {
  margin: 10px;
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  background-color: #0056b3;
}

header {
  background-color: #E6E6FA;
  color: white;
  padding: 10px 0;
}

header .logo {
  text-align: center;
  margin-bottom: 10px;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

header nav ul li {
  margin: 0 15px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

header nav ul li a:hover {
  text-decoration: underline;
}

.placeholder-logo {
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    background-color: #0056b3;
    color: #ffffff;
}

.flex-container {
    display: flex;
    width: 100%;
}

input, select, textarea {
    box-sizing: border-box;
    max-width: 100%;
}

/* Global Date Picker styles */
.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  padding: 8px 12px;
  height: auto;
  background-color: white;
}

.react-date-picker__inputGroup {
  min-width: 100%;
  padding: 2px 0;
}

.react-date-picker__button {
  padding: 4px;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0056b3;
}

/* Error state */
.error .react-date-picker__wrapper {
  border-color: #f44336 !important;
}

/* Calendar popup styles */
.react-calendar {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: inherit;
}

