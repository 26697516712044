/* Top banner */
.top-banner {
  background-color: #262f3e; /* Changed from black to #262f3e */
  color: white;
  text-align: center;
  font-size: 14px;
  padding: 8px 0;
  font-weight: 500;
  border-bottom: none;
  box-shadow: none;
}

.top-banner a {
  color: white;
  text-decoration: underline;
}

/* Main Header */
.main-header {
  display: flex;
  align-items: flex-start; /* Changed from center to flex-start */
  background-color: #E6E6FA;
  border-bottom: none;
  box-shadow: none;
  padding: 5px 10px; /* Reduced from 15px to 10px */
  min-height: 60px; /* Increased from 20px to 60px to provide more vertical space */
}

/* Logo */
.logo {
  font-size: 32px; /* Increased from 24px */
  font-weight: bold;
  margin-top: 15px; /* Add a small top margin to position it precisely */
  line-height: 1; /* Tighten the line height */
}

/* Logo container styling */
.logo-container {
  display: flex;
  align-items: center;
  margin-right: 10px; 
  padding-top: 40px; /* Increased from 10px to 20px to push logo more down */
  margin-bottom: 10px; /* Add bottom margin to balance the container */
}

/* Header logo styling */
.header-logo {
  height: 40px; /* Base height, but we'll use transform instead */
  width: auto;
  display: block;
  transform: scale(2.75); /* Scale the image up by 1.5x without affecting layout */
  transform-origin: left center; /* Scale from the left side */
  margin-right: 10px; /* Add some extra margin to accommodate larger visual size */
  transition: transform 0.2s ease;
}

/* Optional: Adjust hover scale slightly */
.header-logo:hover {
  transform: scale(1.55); /* Just a tiny bit larger on hover */
}

/* Add styling for logo text container */
.logo-text-container {
  margin-left: 15px; /* Move text more to the right */
  display: flex;
  align-items: flex-start; /* Change from center to flex-start to align to top */
  padding-top: 0px; /* Remove padding to move text up */
}

/* Search Bar */
.search-bar {
  flex: 1;
  margin: 0 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Header Icons */
.header-icons {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap between icon items if needed */
  color: #555;
  margin-left: 20px; /* Add margin to the left of the header icons */
}

/* Individual Icon Items */
.icon-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

/* Store Selector */
.bold {
  font-weight: bold;
}


