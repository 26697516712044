.error {
  border: 2px solid red;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  margin-top: 5px;
  /* Space between input field and error message */
  display: block;
  text-align: left;
  width: 100%;
  min-height: 1rem;
  /* Reserve space for error message to prevent layout shifts */
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  /* Increased space to accommodate error message */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  /* Ensure padding is included in width calculation */
}

/* Container for the entire form to provide consistent width */
.signup-form-container {
  width: 80%;
  max-width: 450px;
  /* Optional: prevents the form from getting too wide on large screens */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Style for the form element itself */
.signup-form-container form {
  width: 100%;
  /* Make the form take up all the space in the container */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center the form elements */
}

/* Style for the submit button */
.submit-button {
  background-color: #734f96;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  /* Make the button take up the full width */
  box-sizing: border-box;
  /* Ensure padding is included in width calculation */
}

/* Style for button on hover */
.submit-button:hover {
  background-color: #734f96;
}

/* Adding responsive behavior for smaller screens */
@media (max-width: 768px) {
  .signup-form-container {
    width: 95%;
    /* Use more of the screen on mobile devices */
  }
}

/* Custom styling for phone input */
.phone-input-wrapper {
  position: relative;
  width: 100%;
}

.phone-prefix {
  position: relative;
  left: 10px;
  top: 30px;
  color: #888;
  pointer-events: none;
  z-index: 2;
}

.phone-input {
  padding-left: 30px !important;
  /* Make space for the prefix */
}

/* Additional styling for placeholder text */
::placeholder {
  color: #999;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #999;
}

::-ms-input-placeholder {
  color: #999;
}

/* Add this to your existing CSS */

.general-error {
  background-color: #ffeded;
  /* Light red background */
  border: 1px solid #f5c6cb;
  color: #721c24;
  /* Dark red text */
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

/* Remove all existing react-date-picker styles and add these instead */

/* Custom date input wrapper */
.custom-date-input {
  position: relative;
  width: 100%;
}

.date-format-hint {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 14px;
  pointer-events: none;
}

/* Date Picker styling */
.react-date-picker {
  width: 100%;
  height: 40px;
}

/* Make it look like a single field */
.react-date-picker__wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
}

/* Force the month/day/year inputs to appear as a single field */
.react-date-picker__inputGroup {
  display: flex;
  min-width: 0;
  flex-grow: 1;
}

.react-date-picker__inputGroup__input {
  min-width: 1.5ch;
  height: 100%;
  position: relative;
  padding: 0;
  border: 0;
  background: none;
  color: #000;
  font-size: 16px;
  text-align: center;
}

/* Hide dividers and make it look like single field */
.react-date-picker__inputGroup__divider {
  color: #999;
}

/* Calendar improvements */
.react-calendar {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-family: inherit;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  width: 350px;
  max-width: 100%;
  padding: 10px;
  z-index: 9999;
}

/* Darker, more visible colors for active states */
.react-calendar__tile--active {
  background-color: #734f96 !important;
  color: white !important;
  font-weight: bold;
}

.react-calendar__navigation button {
  font-size: 16px;
  color: #333;
}

.react-calendar__tile {
  padding: 10px;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.react-calendar__tile:enabled:hover,
.react-calendar__navigation button:enabled:hover {
  background-color: #e6e6e6;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 15px 10px;
  font-size: 15px;
}

/* Error state */
.react-date-picker.error .react-date-picker__wrapper {
  border-color: red;
}

/* Media queries for responsive design */
@media (max-width: 480px) {
  .react-calendar {
    width: 290px;
  }
  
  .react-calendar__tile {
    padding: 8px 5px;
    font-size: 13px;
  }
}

/* Add these rules to hide the unwanted elements */

/* Hide the "0/0/" placeholder text when no date is selected */
.react-date-picker__inputGroup__input:invalid {
  visibility: hidden;
  width: 0;
  position: absolute;
}

/* Hide the 0/0/ when no date is selected */
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: 0;
  padding: 0;
  visibility: hidden;
  width: 0;
}

/* Hide the small checkbox controls */
.react-date-picker__clear-button,
.react-date-picker__calendar-button {
  display: none;
}

/* Hide the small dividers that appear between inputs when empty */
.react-date-picker__inputGroup__divider:empty {
  display: none;
}

/* Make the date input look cleaner when empty */
.react-date-picker__inputGroup:empty + .date-format-hint {
  left: 10px;
  right: auto;
}

/* Fix input width when date is selected */
.react-date-picker__inputGroup__input:valid {
  min-width: 0.8ch;
  padding: 0 1px;
}

/* Ensure proper spacing when date values are present */
.react-date-picker__inputGroup__divider {
  padding: 0 2px;
}

/* Add or update these styles */

/* Make the date input fields stay visible */
.react-date-picker__inputGroup__input:invalid {
  opacity: 1 !important;
  visibility: visible !important;
  position: relative !important;
  min-width: 1ch !important;
  width: auto !important;
}

/* Fix the spacing between date segments */
.react-date-picker__inputGroup__divider {
  display: inline-block !important;
  visibility: visible !important;
  width: auto !important;
  opacity: 1 !important;
}

/* Ensure proper width for date input fields */
.react-date-picker__inputGroup__day,
.react-date-picker__inputGroup__month,
.react-date-picker__inputGroup__year {
  width: auto !important;
  display: inline-block !important;
  padding: 0 1px !important;
}

/* Year view styling */
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
  font-size: 15px !important;
  padding: 15px 10px !important;
  color: #333 !important;
}

/* Active selection in all views */
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #734f96 !important;
  color: white !important;
  font-weight: bold !important;
}

/* Fix hidden placeholder issue */
.react-date-picker__inputGroup__input::placeholder {
  opacity: 1 !important;
  visibility: visible !important;
  color: #999 !important;
}

/* Make year view more visible */
.react-calendar__navigation button,
.react-calendar__navigation__label {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #333 !important;
}

/* Fix calendar navigation styles */
.react-calendar__navigation {
  display: flex;
  margin-bottom: 10px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border: 0;
  padding: 10px;
  cursor: pointer;
}

.react-calendar__navigation__label {
  flex-grow: 1;
  font-weight: bold;
  font-size: 16px;
}

/* Improve visibility of decade/year/month options */
.react-calendar__decade-view__years__year,
.react-calendar__year-view__months__month, 
.react-calendar__month-view__days__day {
  padding: 10px !important;
  color: #333 !important;
  font-weight: normal !important;
  background: none !important;
}

.react-calendar__decade-view__years__year:hover,
.react-calendar__year-view__months__month:hover,
.react-calendar__month-view__days__day:hover {
  background-color: #f0f0f0 !important;
}

/* Selected items */
.react-calendar__tile--active,
.react-calendar__decade-view__years__year--active,
.react-calendar__year-view__months__month--active,
.react-calendar__month-view__days__day--active {
  background-color: #734f96 !important;
  color: white !important;
}