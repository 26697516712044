.comm-settings-container {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.comm-settings-title {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.comm-settings-section {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

/* Checkbox row styling */
.comm-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.comm-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.comm-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
}

/* Contact row styling */
.comm-contact-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.comm-field {
  flex: 1;
  min-width: 200px;
}

.comm-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Input styling */
.comm-input {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Array items styling */
.comm-array {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.comm-array-item {
  display: flex;
  gap: 10px;
}

.comm-array-item .comm-input {
  flex: 1;
}

/* Button styling */
.comm-add-button, 
.comm-remove-button {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.comm-add-button {
  background-color: #007bff;
  color: white;
  align-self: flex-start;
}

.comm-remove-button {
  background-color: #dc3545;
  color: white;
}

.comm-add-button:hover {
  background-color: #0069d9;
}

.comm-remove-button:hover {
  background-color: #c82333;
}

.comm-help-text {
  font-size: 14px;
  color: #6c757d;
  margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .comm-contact-row,
  .comm-checkboxes {
    flex-direction: column;
    gap: 10px;
  }
}