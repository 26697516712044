.create-club-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.page-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.general-error {
    background-color: #ffebee;
    color: #d32f2f;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
}

/* Two-column layout */
.club-editor {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    min-height: 650px;
    height: calc(100vh - 150px);
    /* Take up most of the viewport height */
}

/* Left sidebar navigation */
.field-navigation {
    width: 350px;
    /* Keep the increased width */
    flex: 0 0 350px;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.field-navigation h3 {
    padding: 20px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    background-color: #eeeeee;
}

/* Field navigation items - updated layout */
.field-nav-item {
    padding: 15px 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #555;
    border-bottom: 1px solid #e8e8e8;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal columns */
    align-items: start;
    gap: 10px;
}

.field-nav-item:hover {
    background-color: #e0e0e0;
}

.field-nav-item.active {
    background-color: #3f51b5;
    color: white;
    font-weight: 500;
}

.field-nav-label {
    font-weight: 500;
    padding-right: 10px;
    text-align: left;
}

/* Field navigation values - adjust for wider sidebar */
.field-nav-value {
    font-size: 14px;
    word-break: break-word;
    /* Allow long words to break */
    text-align: left;
    /* Left-align values */
    white-space: pre-wrap;
    /* Keep line breaks */
    opacity: 0.85;
}

.field-nav-item.active .field-nav-value {
    color: white;
    opacity: 0.95;
}

/* For multi-line values */
.field-nav-multiline {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.field-nav-multiline-item {
    font-size: 13px;
    padding: 2px 0;
}

/* Right content area - adjust padding for better spacing */
.field-content {
    flex: 1;
    overflow-y: auto;
    padding: 30px 40px;
    /* Added more horizontal padding */
}

.field-content form {
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Field editor adjustments for wider inputs */
.field-editor {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    /* Changed to column to make inputs full-width */
    width: 100%;
    gap: 20px;
}

.field-editor h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
}

/* Child elements within field-editor will display in a row */
.field-editor .form-group {
    width: 100%;
    /* Take full width of parent */
    min-width: initial;
    /* Remove min-width constraint */
}

/* Form styling - ensure inputs use more available width */
.form-group {
    margin-bottom: 25px;
    width: 100%;
    max-width: 800px;
    /* Ensure inputs have a reasonable maximum width */
}

.form-group label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    color: #333;
    font-size: 16px;
}

.form-control {
    width: 100%;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.form-control.error {
    border-color: #d32f2f;
}

/* Textareas should fill available width */
textarea.form-control {
    min-height: 200px;
    resize: vertical;
    width: 100%;
}

.error-message {
    color: #d32f2f;
    font-size: 14px;
    margin-top: 6px;
}

/* Checkbox styling */
.checkbox-group {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.checkbox-label input {
    margin-right: 12px;
    width: 20px;
    height: 20px;
}

/* File upload styling */
.file-list-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
}

.file-list-container h4 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
}

.file-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 12px 16px;
    border-radius: 4px;
    margin-bottom: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.file-name {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.remove-file {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
}

.remove-file:hover {
    background-color: #d32f2f;
}

/* Group fields - special case for address */
.group-editor {
    background-color: #fafafa;
    padding: 25px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 800px;
    /* Ensure consistency with other inputs */
}

/* Address field rows */
.form-group-row {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    width: 100%;
}

/* Address fields within rows */
.form-group-row .form-group {
    flex: 1;
    min-width: 0;
    /* Allow fields to shrink */
    margin-bottom: 10px;
}

/* Button actions - MOVE NEXT BUTTON CLOSER TO INPUTS */
.field-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    /* Reduced from 10px */
    padding-top: 10px;
    /* Reduced from 20px */
    border-top: none;
    /* Remove border for cleaner look */
}

.button-primary {
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    min-width: 120px;
}

.button-primary:hover {
    background-color: #303f9f;
}

.button-primary:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
}

.button-secondary {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    min-width: 120px;
    margin-left: 15px;
}

.button-secondary:hover {
    background-color: #e0e0e0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .club-editor {
        flex-direction: column;
        height: auto;
        min-height: 0;
    }

    .field-navigation {
        width: 100%;
        flex: none;
        border-right: none;
        border-bottom: 1px solid #e0e0e0;
    }

    .field-nav-item {
        padding: 12px 15px;
    }

    .field-content {
        padding: 20px;
    }

    .field-actions {
        flex-wrap: wrap;
        margin-top: 5px;
    }

    .button-primary,
    .button-secondary {
        margin: 5px;
        width: 100%;
    }

    .button-secondary {
        margin-left: 0;
    }

    .form-group {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .field-navigation {
        padding: 8px;
    }

    .field-nav-item {
        flex: 1 0 40%;
        font-size: 13px;
        padding: 8px;
    }

    .form-group label {
        font-size: 15px;
    }

    .form-control {
        padding: 10px;
        font-size: 15px;
    }
}

/* React DatePicker Styles */
.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  border: 1px solid #ccc !important;
  border-radius: 4px;
  padding: 12px !important;
  height: auto;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
}

.react-date-picker__inputGroup {
  min-width: 100%;
  padding: 2px 0;
}

.react-date-picker__button {
  padding: 4px;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #3f51b5;
}

/* Error state */
.error .react-date-picker__wrapper,
.react-date-picker__wrapper.error {
  border-color: #d32f2f !important;
}

/* Calendar popup styles */
.react-calendar {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  border: 1px solid #e0e0e0;
}

.react-calendar__tile--active {
  background-color: #3f51b5 !important;
  color: white;
}

.react-calendar__tile--now {
  background-color: #e6e6e6;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__tile:enabled:hover {
  background-color: #f0f0f0;
}

@media (max-width: 480px) {
  .react-date-picker__wrapper {
    padding: 10px !important;
  }
}