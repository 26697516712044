.tournament-registration-popover {
  /* Use fixed positioning for viewport-relative placement */
  position: fixed !important; /* !important to override any inline styles */
  
  /* Center in the viewport */
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  
  /* Styling */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  width: 600px;
  max-width: 95vw;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
  padding: 0;
  
  /* Add a subtle left border */
  border-left: 4px solid #4a90e2;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  background-color: #f8f9fa;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.popover-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
  color: #666;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #333;
}

.tournament-registration-popover form {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-group select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
}

.form-group select:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancel-button {
  padding: 10px 16px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e9ecef;
}

.submit-button {
  padding: 10px 16px;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #3a80d2;
}

/* Add to your TournamentRegistrationPopover.css file */
.division-select option.suggested-option {
  font-weight: bold;
  background-color: #e8f5e9;
}

/* Suggested division styling */
.division-select option.suggested-option {
  font-weight: bold;
  background-color: #e8f5e9;
}

/* Add a special background color for the selected division in the dropdown */
.division-select {
  background-color: white;
}

/* Add a subtle highlight for the currently selected division */
.division-select:focus option:checked {
  background: linear-gradient(#4a90e2, #4a90e2);
  background-color: #4a90e2 !important; /* For some browsers */
  color: white;
}

/* Custom multi-select styling */
.custom-multi-select {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  width: 100%;
}

.custom-multi-select .selected-items {
  min-height: 38px;
  padding: 4px 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  cursor: pointer;
}

.custom-multi-select .placeholder {
  color: #6c757d;
  padding: 4px 0;
}

.custom-multi-select .selected-tag {
  display: inline-flex;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 3px;
  padding: 2px 8px;
  margin: 2px 0;
  font-size: 14px;
}

.custom-multi-select .remove-tag {
  background: none;
  border: none;
  color: #495057;
  margin-left: 5px;
  padding: 0 2px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}

.custom-multi-select .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  display: none;
}

.custom-multi-select:focus-within .dropdown,
.custom-multi-select .selected-items:focus + .dropdown,
.custom-multi-select:hover .dropdown {
  display: block;
}

.custom-multi-select .option {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-multi-select .option:hover {
  background-color: #f8f9fa;
}

.custom-multi-select .option.selected {
  background-color: #e9ecef;
}

.custom-multi-select .option.suggested {
  background-color: #e8f5e9;
  font-weight: bold;
}

.custom-multi-select .option.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-multi-select .option input {
  margin-right: 8px;
}

/* Partner information styling */
.partner-info-container {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  border-left: 3px solid #4a90e2;
}

.partner-info-container h4 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #4a90e2;
  font-size: 16px;
}

.partner-info-section {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9ecef;
}

.partner-info-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.partner-info-section h5 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #495057;
  font-size: 14px;
  font-weight: 600;
}

.proposed-divisions-section {
  margin-top: 8px;
  padding: 8px 0;
  border-top: 1px solid #eee;
}

.proposed-divisions-section h4 {
  margin: 0 0 8px 0;
  font-size: 0.9rem;
  color: #555;
}

.proposed-divisions-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.proposed-division-item {
  padding: 6px 12px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.proposed-division-item:hover {
  background-color: #e9e9e9;
  border-color: #ccc;
}

.proposed-division-item.selected {
  background-color: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}